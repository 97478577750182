<template>
  <div class="flex-grow-1 pb-12">
    <UserTitle hideBack>
      <template v-slot:actions>
        <div class="d-flex align-center flex-grow-1 justify-space-between">
          <h2 class="text-h4 font-weight-black">Account Settings</h2>
        </div>
      </template>
    </UserTitle>

    <v-row class="mt-n3">
      <v-col cols="12" v-if="user && user.role=='jobseeker'">
        <v-card class="mt-4 shadow-lg">
          <v-card-text class="pa-5">
            <v-row dense>
              <v-col cols="12">
                <v-alert type="error" border="left" class="mb-6" v-if="status.error">{{ status.error }}</v-alert>

                <preloader v-if="!user" />
                <v-form ref="accountForm" v-if="user">
                  <v-row dense>
                    <v-col cols="12">
                      <div :class="$text.label">Signup Method</div>
                      <v-text-field
                        :value="getSignupMethod()"
                        hide-details
                        outlined
                        readonly
                        dense
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6">
                      <div :class="$text.label">Account Created</div>
                      <v-text-field
                        :value="convertTime(currentUser.metadata.creationTime)"
                        hide-details
                        outlined
                        readonly
                        dense
                      ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" md="6">
                      <div :class="$text.label">Last Login</div>
                      <v-text-field
                        :value="convertTime(currentUser.metadata.lastSignInTime)"
                        hide-details
                        outlined
                        readonly
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                </v-form>

                <div class="mt-5 mb-3">
                  <span class="font-weight-black primary--text title">Password Setting</span>
                </div>

                <v-alert class="mt-5" type="info" border="left" v-if="getSignupMethod() !== 'Email and Password'">
                  Password cannot be updated if your account was created using Google and Facebook.
                </v-alert>

                <v-alert v-if="status.passwordError" type="error" class="mb-7" border="left">{{ status.passwordError }}</v-alert>

                <v-form ref="passwordForm" v-if="getSignupMethod() == 'Email and Password'" @submit.prevent="validatePassword()">
                  <v-row dense>
                    <v-col cols="12">
                      <div :class="$text.label">Current Password</div>
                      <v-text-field
                        v-model="password.current"
                        :rules="[rules.required]"
                        type="password"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <div :class="$text.label">New Password</div>
                      <v-text-field
                        v-model="password.new"
                        :rules="[rules.required, rules.password]"
                        type="password"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <div :class="$text.label">Confirm Password</div>
                      <v-text-field
                        :rules="[rules.required, (value) => value == password.new || 'Password did not matched.']"
                        type="password"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <div class="d-flex gap-10 mt-5">
                    <v-btn
                      @click="validatePassword()"
                      :loading="status.validatingPassword"
                      color="accent gradient"
                    >
                      Update Password
                    </v-btn>

                    <v-btn
                      @click="clearPasswordForm()"
                      :disabled="status.validatingPassword"
                      depressed
                    >Clear</v-btn>
                  </div>

                  <div class="mt-7">
                    <div class="mb-3">Did you forget your current password?</div>

                    <v-btn 
                      @click="resetPassword()" 
                      :loading="status.resetting"
                      color="info"
                      outlined 
                      small
                    >Send Reset Email</v-btn>
                  </div>

                </v-form>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- User Access -->
      <v-col cols="12" v-else>
        <v-card class="mt-4 shadow-lg">
          <v-card-text class="pa-10">
            <v-row>
              <v-col cols="12">
                <v-alert type="error" border="left" class="mb-6" v-if="status.error">{{ status.error }}</v-alert>

                <preloader v-if="!user" />
                <v-form ref="accountForm" v-if="user">
                  <v-row>
                    <v-col cols="12">
                      <div class="mb-2 mb-sm-6">
                        <UserPhoto
                          :id="user.userid"
                          :size="150"
                          tile
                          photoSize="small"
                          editable
                        />
                      </div>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-text-field
                        v-model="user.firstName"
                        :rules="[rules.required]"
                        label="First Name"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="12" md="6">
                      <v-text-field
                        v-model="user.lastName"
                        :rules="[rules.required]"
                        label="Last Name"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col v-if="user.role == 'employer'" class="py-0" cols="12">
                      <v-text-field
                        v-model="user.jobTitle"
                        label="Job Title"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="12">
                      <v-text-field
                        v-model="user.email"
                        :rules="[rules.required, rules.email]"
                        label="Email Address"
                        :append-icon="getSignupMethod() !== 'Email and Password' ? 'mdi-lock' : ''"
                        :hint="getSignupMethod() !== 'Email and Password' ? 'Email cannot be updated if your account was created using Google and Facebook.' : ''"
                        :readonly="getSignupMethod() !== 'Email and Password' || !status.accountConfirmed"
                        @click="confirmEmail()"
                        persistent-hint
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-text-field
                    label="Signup Method"
                    :value="getSignupMethod()"
                    append-icon="mdi-lock"
                    outlined
                    readonly
                  ></v-text-field>

                  <v-row>
                    <v-col cols="12" md="6" class="py-0">
                      <v-text-field
                        label="Account Created"
                        :value="convertTime(currentUser.metadata.creationTime)"
                        append-icon="mdi-lock"
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="py-0">
                      <v-text-field
                        label="Last Login"
                        :value="convertTime(currentUser.metadata.lastSignInTime)"
                        append-icon="mdi-lock"
                        outlined
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <div v-if="user.role == 'jobseeker'">
                    <v-switch
                      v-model="user.public"
                      label="Publicly Visible"
                      class="mt-0 mb-5"
                      hide-details
                    ></v-switch>
                  </div>

                  <v-btn
                    @click="validateProfile()"
                    :loading="status.updating"
                    color="accent gradient"
                    dark
                  >
                    Update
                  </v-btn>
                </v-form>

                <div class="mt-10 mb-3">
                  <span class="font-weight-black primary--text title">Password Setting</span>
                </div>

                <v-divider class="mb-5"></v-divider>

                <v-alert type="info" border="left" v-if="getSignupMethod() !== 'Email and Password'">
                  Password cannot be updated if your account was created using Google and Facebook.
                </v-alert>

                <v-alert v-if="status.passwordError" type="error" class="mb-7" border="left">{{ status.passwordError }}</v-alert>

                <v-form ref="passwordForm" v-if="getSignupMethod() == 'Email and Password'" @submit.prevent="validatePassword()">
                  <v-row>
                    <v-col cols="12" class="py-0">
                      <v-text-field
                        v-model="password.current"
                        :rules="[rules.required]"
                        label="Current Password"
                        type="password"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                        v-model="password.new"
                        :rules="[rules.required, rules.password]"
                        label="New Password"
                        type="password"
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                      <v-text-field
                        :rules="[rules.required, (value) => value == password.new || 'Password did not matched.']"
                        label="Confirm Password"
                        type="password"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <div class="d-flex gap-12 mt-5">
                    <v-btn
                      @click="validatePassword()"
                      :loading="status.validatingPassword"
                      color="accent gradient"
                    >
                      Update Password
                    </v-btn>

                    <v-btn
                      @click="clearPasswordForm()"
                      :disabled="status.validatingPassword"
                      depressed
                    >Clear</v-btn>
                  </div>

                  <div class="mt-7">
                    <div class="mb-3">Did you forget your current password?</div>

                    <v-btn 
                      @click="resetPassword()" 
                      :loading="status.resetting"
                      color="info"
                      outlined 
                      small
                    >Send Reset Email</v-btn>
                  </div>

                </v-form>

              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>
    <ConfirmPassword />
  </div>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import locations from '@/assets/json/au_locations.json'
import firebase from 'firebase'
import moment from 'moment'
import rules from '@/rules'

export default {
  name: 'AccountSettings',

  metaInfo: {
    title: 'Account Settings'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      profileData: [],
      auLocation: locations,
      label: 'heading-font font-weight-bold primary--text mb-1',
      educations: [{
        schoolName: '',
        fieldOfStudy: '',
        yearStart: '',
        yearEnd: '',
        introduction: '',
        achievements: '',
      }],
      teachingLvl: [
        'Graduate',
        '2-4 years',
        '4-10 years',
        '10+ years'
      ],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.user.status,
      password: state => state.user.password,
      profile: state => state.profile.profile,
      hasProfile: state => state.profile.hasProfile,
      education: state => state.profile.education,
      experience: state => state.profile.experience,
      referees: state => state.profile.referees,
      profileStatus: state => state.profile.status,
      files: state => state.profile.files,
      attachments: state => state.profile.attachments,
      positions: state => state.position.data,
      positionStatus: state => state.position.status,
    }),

    currentUser: function () {
      if (firebase.auth().currentUser) {
        return firebase.auth().currentUser
      }
      else {
        return {}
      }
    },

    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 1970}, (value, index) => 1971 + index).reverse()
    },

  },

  watch: {
    'profile': function (profile) {
      if(profile.position) {
        this.getPositionList()
      }
    }
  },


  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('user', [
      'updateProfile',
      'updatePassword',
      'resetPassword'
    ]),

    ...mapActions('position', [
        'getPositions',
    ]),

    ...mapActions('profile', [
      'storeProfileDetails',
      'getProfile',
      'uploadFile',
      'deleteAttachment',
      'updateProfileInfo',
    ]),

    limiter(e) {
      if(e.length > 25) {
        this.$refs.ableToSuperviseUpdate._props.errorMessages.push('Limit of 25 items in skillsets')
        e.pop()
      }else{
        this.$refs.ableToSuperviseUpdate._props.errorMessages.forEach(function(item, index, object) {
          object.splice(index, 1)
        })
      }
    },

    getSignupMethod() {
      if (this.currentUser.providerData[0].providerId == 'google.com') return 'Google'
      else if (this.currentUser.providerData[0].providerId == 'facebook.com') return 'Facebook'
      else return 'Email and Password'
    },

    validateProfile() {
      if (this.$refs.accountForm.validate()) {
        this.updateProfile()
      }
    },

    confirmed() {
      this.confirmedPassword = true
    },

    confirmEmail() {
      if (this.getSignupMethod() == 'Email and Password' && !this.status.accountConfirmed) {
        this.$store.commit('user/setShowConfirmPassword', true)
      }
    },

    validatePassword() {
      if (this.$refs.passwordForm.validate()) {
        Promise.all([this.updatePassword()])
        .then(() => {
          this.$refs.passwordForm.reset()
        })
      }
    },

    required(value) {
      if (value instanceof Array && value.length == 0) {
        return 'Required.';
      }
      return !!value || 'Required.';
    },

    clearPasswordForm() {
      this.$refs.passwordForm.reset()
    },

    validateProfileInfo(type) {
      if (this.$refs.profileInformation.validate()) {
        if(type == 'add') {
          // Promise.all([this.storeProfileDetails({profile: this.profileData, educations: this.educations, type: 'add'})])
          Promise.all([this.storeProfileDetails({profile: this.profile, educations: this.educations, recentExperience: this.experience, referees: this.referees, type: 'add'})])
          .then(() => {
            this.getProfile()
          })
        }else if(type == 'update') {
          Promise.all([this.storeProfileDetails({profile: this.profile, education: this.education, recentExperience: this.experience, referees: this.referees, type: 'update'})])
          .then(() => {
            this.getProfile()
          })
        }
      }
    },

    cancel() {
      this.$refs.experience.reset()
    },

    save() {
      if(this.$refs.experience.validate()){
        this.updateProfileInfo(this.profile)
      }
    },

    cancelBio() {
      this.$refs.experience.reset()
    },
    
    saveBio() {
      if(this.$refs.bio.validate()){
        this.updateProfile(this.user)
        this.updateProfileInfo(this.profile)
      }
    },

    getAddress(addressData) {
      this.$store.commit('user/setUserAddress', addressData)
    },

    getPositionList() {
        if (!this.positionStatus.firstLoad) this.getPositions()
    },

    convertTime(date) {
      return moment(date).toDate()
    }
  },

  /*------------------------------------------------------------------------------
   * CREATED
   *----------------------------------------------------------------------------*/
  created() {
    if (!this.profileStatus.firstLoad) this.getProfile()
    this.convertTime()
  },

}

</script>


<style scoped lang="scss">
  .eduction, .container-education{
    width: 100%
  }
</style>
